  /* src/components/Slider.css */

  .slider {
    perspective: 1500px;
    width: 100%; /* Adjust to match the image width */
    height: 250px; /* Adjust to match the image height */
    margin: auto;
    position: relative;
    overflow: hidden;
    background-color: rgb(167, 192, 192);
  }

  .slider-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    transition: transform 1s ease-in-out;
  }

  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slide img {
    width: 70%; /* Ensure the image fits within the container */
    height: 100%;
    object-fit:contain; /* Ensure that the entire image is visible without cropping */
    backface-visibility: hidden;
    border-radius: 10px; /* Optional: Add a small border radius */
  }

  /* Adjusting the translateZ value for the images */
  .slide:nth-child(1) {
    transform: rotateY(0deg) translateZ(450px);
  }

  .slide:nth-child(2) {
    transform: rotateY(72deg) translateZ(450px);
  }

  .slide:nth-child(3) {
    transform: rotateY(144deg) translateZ(450px);
  }

  .slide:nth-child(4) {
    transform: rotateY(216deg) translateZ(450px);
  }

  .slide:nth-child(5) {
    transform: rotateY(288deg) translateZ(450px);
  }


  @media only screen and (max-width: 600px) {
    .slider {
      perspective: 1500px;
      width: 100%; /* Adjust to match the image width */
      height: 250px; /* Adjust to match the image height */
      margin: auto;
      position: relative;
      overflow: hidden;
    }
  }