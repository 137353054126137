.form-container {
    width: 60%;
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-top: 50px;
    /* margin-right: 100px; */
  }

  .form-container > form{
    padding: 40px;
    margin-top: 10px;
  }
  
  h2 {
    text-align: left;
    color: #333;
    font-size: 25px;
  }
  
  .form-group {
    margin-bottom: 15px;
    /* border: 1px solid red; */
  }
  
 .form-group > label {
    display: block;
    /* margin-bottom: 5px; */
    /* border: 2px solid blue; */
    width: 200px;
    margin-top: 20px;
    margin-left: 5px;
    color: #333;
  }
  
  select, input, textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #e6e4e4;
    border-radius: 4px;
    /* margin-top: -10px; */
  }
  
 #user-id{
    width: 100%;
 }

 #amount{
    width: 100%;
 }

  textarea {
    resize: vertical;
  }
  
  .submit-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  