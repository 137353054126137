.swiper {
  width: 78%;
  height: auto;
  margin-top: 60px;
  z-index: 0;
  position: relative; /* Necessary for pseudo-element positioning */
}

.swiper-slide {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensure the pseudo-element doesn't spill over */
}

.swiper-slide::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url('./earning_banner2.png'); */
  background-position: center;
  background-size: cover;
  filter: blur(3px); /* Applies the blur effect to the background */
  z-index: 0; /* Ensures the background stays below the content */
}

.swiper-slide img {
  width: 350px; /* Set fixed width */
  height: 300px; /* Set fixed height to match width */
  object-fit: cover; /* Ensures the image fits within the dimensions */
  position: relative; /* Ensures the image stays above the blurred background */
  z-index: 1; /* Keeps the image on top of the blurred background */
}

/* Mobile view */
@media only screen and (max-width: 600px) {
  .swiper {
    width: 76%;
    height: auto;
    margin-top: 60px;
  }

  .swiper-slide::before {
    background-image: url('./earning_banner2.png');
    filter: blur(8px); /* Applies a blur effect */
  }

  .swiper-slide img {
    width: 350px; /* Set width for mobile view */
    height: 300px; /* Set height for mobile view to match width */
    object-fit: cover;
    z-index: 1; /* Keeps the image above the blurred background */
  }
}
