/* qrPaymentRequests.css */
table {
    width: 100%;
    border-collapse: collapse;
  }
  
  thead th {
    background-color: #e2e2e2;
  }
  
  tbody tr:hover {
    background-color: #f5f5f5;
  }
  
  button {
    cursor: pointer;
  }
  
  button:disabled {
    cursor: not-allowed;
  }
  