/* RocketGame.css */

.rocket-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  width: 40%;
  margin: auto;
  background: linear-gradient(to bottom, #22c55e, #3b82f6);
  height: 745px;
  
  padding-top: 100px;
}

.rocket {
  width: 300px;
  margin: 0 auto;
  position: relative;
  margin-top: 220px;
  margin-bottom: 10px;
  transition: transform 1.5s ease-in-out;
}

.rocket.flying {
  transform: translateY(-300px);
}

.launch-button {
  padding: 10px 20px;
  font-size: 1.5rem;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 20px;
}

.launch-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.launch-button:not(:disabled):hover {
  background-color: #218838;
  transform: scale(1.05);
}

#confetti {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;
  pointer-events: none;
}

.confetti-piece {
  position: absolute;
  width: 10px;
  height: 20px;
  background-color: #ff5733;
  animation: fall 3s linear infinite;
  opacity: 0.7;
}

@keyframes fall {
  0% {
    transform: translateY(-100vh) rotate(0deg);
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
  }
}

#congrats-message {
  display: none;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 20px;
  color: #ffc107;
}

@media only screen and (max-width: 600px) {
  .rocket-game {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    width: 99%;
    margin: auto;
    background: linear-gradient(to bottom, #22c55e, #3b82f6);
    height: 745px;
    padding-top: 100px;
  }

  #confetti {
    position: fixed;
    top: 0;
    /* left: 50%; */
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    margin: auto;
    pointer-events: none;
    z-index: 1000;
    overflow: hidden;
    display: none;
  }
}